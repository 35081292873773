/* Ensure the body and root elements take the full height of the viewport */
body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  margin: 0;
  background-color: #efefef;
}

#root {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

/**********/
/* NAVBAR */
/**********/
#navbar-top {
  height: 125px;
  width: 100%;
  background-color: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  justify-content: space-between;
}

.navbar-full-size {
  height: 45px;
  width: 100%;
  background-color: #e95353;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.navbar-logo-container {
  flex: 1;
  display: flex;
  justify-content: center;
}

.navbar-logo {
  margin-top: 15px;
}

.navbar-description {
  flex: 2;
  text-align: center;
  color: white;
  font-size: 18px;
  margin: 0px 0px 15px 0px;
}

.hamburger-menu {
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: absolute;
  right: 20px;
  top: 27px;
}

.bar {
  width: 25px;
  height: 3px;
  background-color: white;
  margin: 4px 0;
}

#page-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content-container {
  margin: 45px 10%;
  text-align: center;
  flex-grow: 1;
}

button {
  height: 100%;
  padding: 0 20px;
  font-size: large;
  font-weight: bold;
  color: white;
  background-color: #e95353;
  border: none;
  cursor: pointer;
}

button:hover {
  background-color: #e18080;
}

p {
  font-size: 17px;
  text-align: center;
}

span {
  font-weight: bold;
}

.navbar-link {
  text-decoration: none;
  height: 100%;
}

/*************/
/* HOME PAGE */
/*************/
.container {
  display: flex;
  background-color: black;
  flex-wrap: wrap;
  align-items: center;
}

.text-container {
  width: 50%;
  margin: 20px 0;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 0 30px;
  text-align: center;
}

.text {
  color: white;
}

.image-container {
  width: 50%;
  margin: 0;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
}

.image {
  width: 100%;
  height: 100%;
}

.testimonials-box {
  padding: 20px 40px;
  margin: 20px;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.setlist-container {
  display: flex;
  flex-direction: column;
  background-color: white;
}

.setlist-row {
  width: 100%;
  display: flex;
  align-items: flex-start;
}

.setlist-section {
  width: 50%;
  text-align: center;
  justify-content: center;
  margin-bottom: 33px;
}

#grid {
  display: flex;
  margin-top: 45px;
}

.homepage-img-grid {
  max-width: 100%;
  height: auto;
  object-fit: cover;
  display: block;
  width: 100%; /* Ensure the images take the full width of their container */
}

#pics-col-1,
#pics-col-2,
#pics-col-3 {
  padding: 5px; /* Add padding to each column */
}

#pics-col-2 img {
  height: calc(50% - 5px);
}

.video {
  width: 100%;
  aspect-ratio: 16 / 9;
}

.page-heading {
  font-size: xxx-large;
  text-decoration: underline;
  text-decoration-color: #e95353;
  text-decoration-skip-ink: none;
  text-underline-offset: 0.2em;
  text-decoration-thickness: 0.2em;
  margin-top: 0px;
  margin-bottom: 50px;
}

#wedding-images-container {
  display: flex;
  justify-content: space-between;
}

.wedding-image {
  width: 49%;
  margin: 10px 0px;
}

/* Medium windows */
@media (max-width: 1000px) {
  .container {
    flex-direction: column;
    align-items: flex-start;
  }

  .text-container,
  .image-container {
    width: 100%;
  }
}

/* Small windows */
@media (max-width: 768px) {
  #navbar-top {
    height: 80px;
  }

  .navbar-description {
    display: none;
  }

  .navbar-logo {
    margin: 0;
    padding: 5px 0px;
    max-width: 500px;
    height: 100%; /* Ensure it fills the vertical space */
    object-fit: contain; /* Ensure it maintains aspect ratio */
  }

  .hamburger-menu {
    display: flex;
  }

  .navbar-dropdown-open {
    max-height: 400px;
    flex-direction: column;
  }

  .navbar-dropdown-closed {
    display: none;
  }

  .navbar-link {
    width: 100%;
    height: 100%;
    text-align: center;
    padding: 10px 0;
  }

  .navbar-link button {
    width: 100%;
    height: 100%;
    background-color: #e95353;
    color: white;
    border: none;
    padding: 15px;
    font-size: 18px;
  }

  .navbar-link button:hover {
    background-color: #cc4343;
  }

  .setlist-row {
    flex-direction: column;
    align-items: center;
  }

  .setlist-section {
    width: 100%;
  }
}

/* Smaller windows */
@media (max-width: 600px) {
  #navbar-top {
    height: 60px;
  }

  .navbar-logo {
    margin: 0;
    padding: 5px 0px;
    max-width: 250px;
    height: 100%; /* Ensure it fills the vertical space */
    object-fit: contain; /* Ensure it maintains aspect ratio */
  }

  #grid {
    margin-top: 15px;
  }

  .content-container {
    margin: 20px 5%;
    text-align: center;
    flex-grow: 1;
  }

  .text-container {
    margin: 0;
  }

  .page-heading {
    font-size: xx-large;
  }

  #wedding-images-container {
    flex-direction: column;
  }

  .wedding-image {
    width: 100%;
  }

  .hamburger-menu {
    top: 15px;
  }
}

/* Smallest windows */
@media (max-width: 350px) {
  #navbar-top {
    height: 75px;
  }

  .navbar-logo {
    margin: 0;
    padding: 10px 0px;
    max-width: 150px;
    height: 100%; /* Ensure it fills the vertical space */
    object-fit: contain; /* Ensure it maintains aspect ratio */
  }

  body {
    overflow-x: auto;
  }

  .hamburger-menu {
    position: static;
  }
}

/* Footer styles to ensure it sticks to the bottom when the content is short */
footer {
  background-color: #333;
  color: white;
  text-align: center;
  padding: 20px 0;
  margin-top: auto; /* Push the footer to the bottom */
}
